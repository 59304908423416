.select-type-title {
    font-family: TT-Norms-Black;
    font-size: 28px;
    font-weight: 900;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #151A35;
    margin-bottom: 33px;
}

.select-type-container .select-type-right-container {
    padding: 0 20px 20px 20px;
}

.document-type-choices {
    display: flex;
    gap: 50px;
}

.document-type-choices input  {
    width: 24px!important;
    height: 24px!important;
    border: 2px solid #151A35;
}

.document-type-choices label {
    text-align: left;
}

.document-type-choices .form-check {
    padding-left: 0;
}

.document-type-choices input  {
    width: 16px;
    height: 16px;
    border: 2px solid #151A35;
}

.document-type-choices input:checked {
    background-color: var(--custom-primary-background-color);
}

.select-type-container {
    margin-top: 15px;
    background: #FAFAFA;
    border-radius: 18px;
    padding: 34px;
    align-items: center;
}

.select-type-container img {
    width: 180px;
    height: 176px;
}

.padding-left {
    padding-left: 0px;
}

@media screen and (max-width: 600px) {
    .select-type-title {
        font-size: 16px;
        font-weight: 900;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
    }

    .document-type-choices {
        justify-content: space-between;
        gap: 20px;
    }

    .select-type-container svg {
        margin: 25px 0;
    }

    .document-type-choices .form-check {
        align-items: unset;
        gap: 5px;
    }

    .select-type-container {
        padding: 15px;
    }

    .document-type-choices label {
        width: unset;
        font-size: 12px;
        align-content: center;
    }

    .select-type-container .form-check .form-check-input {
        margin-top: 0;
    }
}