.radiobutton-html-container {
    display: flex;
}

.radiobutton-html-container label {
    max-width: calc(100% - 50px);
}

.hide-radiobutton-borders .form-check-input {
    border: 0px solid !important;
}