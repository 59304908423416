.passport-flow {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 26px;
    align-items: center;
}

.passport-flow p {
    color: #151A35;
    font-feature-settings: 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    text-align: center;
}

.passport-flow button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 460px;
    height: 56px;
    border-radius: 10px;
    border: 2px solid var(--custom-primary-background-color);
    background-color: var(--custom-primary-background-color);
    padding: 0 27px;
}

.passport-flow button span {
    color: var(--custom-primary-text-color);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
}

.passport-flow button img {
    width: 18px;
}

.send-images-button {
    color: var(--custom-secondary-text-color) !important;
}

.send-images-button:disabled {
    background-color: grey;
    color: white;
    border: none;
}

.send-images-button:disabled polygon {
    fill: #151A35;
}

.send-images-button:disabled span {
    color: #151A35;
}

@media screen and (max-width: 1000px) {
    .passport-flow {
        margin-left: unset;
        align-items: center;
    }

    .passport-flow p {
        text-align: center;
    }
}