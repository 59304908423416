.select-buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.documents-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 10px; 
    margin: 40px 0;
}

.next-documents-buttons-container button {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .documents-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 10px; 
        margin: 20px 0;
    }
    
    .select-all-button span {
        display: none;
    }

    .welcome-text-container h2 {
        font-size: 20px;
    }
}