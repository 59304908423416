.page-image, .page-image-on-preview{
    position: relative;
}

.horizontal-page {
    height: auto;
}

@media (max-width: 600px) {
    .view-mobile-doc {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 10px;
    }

    .page-image {
        margin-right: unset;
    }

    .page-image-on-preview {
        width: calc(100vw - calc(var(--bs-gutter-x)));
        margin-right: 14px;
    }
}