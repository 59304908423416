.success-hr {
    width: 80%;
    display: block;
    margin: 20px auto;
}

.green-text {
    color: var(--custom-primary-background-color);
    font-weight: bold;
}

.documents-list-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    gap: 3px;
    width: auto;
    min-width: 70%;
    margin: auto;
    margin-top: 20px;
    padding-left: 27px;
}

.exit-option {
    background-color: transparent;
    border: none;
    width: 50%;
    display: block;
    margin: auto;
    color: #9C9C9C;
    font-weight: bold;
}

.exit-button {
    width: 50%;
    display: block;
    margin: 80px auto 10px auto;
}

.documents-list-button svg {
    margin-right: 27px;
}

.signature-success {
    margin-top: 20px;
    height: 55vh;
}

@media screen and (max-width: 1000px) {
    .documents-list-button, .exit-button {
        width: auto;
    }
}


@media screen and (max-width: 400px) {
    .documents-list-button {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .padding-content {
        padding: 10px 30px 30px 30px;
    }

    .signature-success {
        margin-top: 20px;
        height: 50vh;
        border-radius: 12px;
    }
}