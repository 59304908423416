.signature-box, .signature-box-ready {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0!important;
}

.signature-box {
    border: 2px dashed var(--custom-secondary-background-color);
    background-color: #E5F9F1;
    cursor: pointer;
    font-family: TT-Norms;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;

}

.signature-box:hover {
    filter: brightness(0.9);
}