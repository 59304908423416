.container-sms-box {
  background-color: #F5F5F5;
  height: 40vh;
  border-radius: 28px;
  margin-top: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-sms-box-2 {
  background-color: #F5F5F5;
  height: 65vh;
  border-radius: 28px;
  margin-top: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sms-title {
  font-family: 'TT-Norms-Black';
  font-size: 28px;
}

.col-align {
  text-align: initial;
}

.button-margin {
  margin-top: 3%;
}

.button-margin button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.phone-numer-img {
  max-width: unset;
  width: unset;
  height: unset;
}

.phone-number-input {
  text-align: inherit;
  font-family: 'TT-Norms-Regular';
}

.phone-number-input:focus {
  color: #000000;
}

.form-control:focus {
  border-radius: 10px;
  border: 1.5px solid var(--custom-primary-background-color);
  box-shadow: unset;
}

@media (max-width: 767px) {
  .container-sms-box {
    margin-top: auto;
    height: 65vh;
    padding: 0px 25px;
  }

  .phone-numer-img {
    width: 150px;
    height: 150px;
  }

  .col-align {
    margin-top: 2vh;
  }

  .phone-number-button {
    width: 90%;
  }

  .phone-number-button svg {
    width: 14px;
    height: 14px;
    margin-right: 27px;
}

  .phone-number-input {
    text-align: center;
    width: 90%;
  }

  .phone-number-form {
    text-align: -webkit-center;
  }

  .button-margin {
    margin-top: 5%;
  }

  .sms-title {
    text-align: center;
  }
}