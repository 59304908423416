.rgpd-text {
    text-align: left;
    margin-top: 2%;
}

.rgpd-text .form-check {
    padding-left: 0;
}

.rgpd-text .form-check-input {
    margin-top: 0;
}


.rgpd-input {
    min-width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 5px !important;
    vertical-align: middle;
    border: 1px solid var(--custom-primary-background-color);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.rgpd-label {
    vertical-align: sub;
}

.more-info {
    color: var(--custom-primary-background-color);
    text-decoration: underline;
    cursor: pointer;
}

@media (min-width: 600px) and (max-width: 800px) {
    .rgpd-text {
        margin-left: 0%;
    }
}

@media (max-width: 600px) {
    .rgpd-input {
        width: 27px;
        margin-right: 10px;
    }

    .form-check {
        display: flex;
        align-items: center;
        padding-left: unset;
    }
}