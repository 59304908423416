.document-preview-container {
    margin: auto;
    margin-top: 5vh;
    max-width: 1000px;
}

.document-preview-hidden {
    visibility: hidden;
}

.document-preview-title {
    font-size: 18px;
    font-weight: 400;
    color: #151A35;
    text-align: left;
    display: flex;
    align-items: center;
}

.document-name,
.download-button,
.scale-zoom {
    font-size: 16px;
    color: #151A35;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: 'TT-Norms-Black';
}

.document-name {
    text-align: left;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
}

.scale-zoom {
    width: unset;
    color: var(--custom-primary-background-color);
    background-image: url('./../../assets/svg/arrow_down.svg');
    background-size: unset;
    border: 2px solid var(--custom-primary-background-color-opacity);
    height: 32px;
    font-size: 0.75rem;

    .form-select:focus {
        box-shadow: 0 0 0 .25rem var(--custom-primary-background-color-opacity);
    }
}

#scale-zoom * {
    font-family: 'TT-Norms';
    font-size: 16px;
    color: #000000;
    text-align: center;
    border-radius: 10px;
    background: #FFF;
}

.download-button {
    text-align: end;
}

.signer-name {
    margin-left: 2%;
}


.pdf-container,
.pdf-container-small {
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #FAFAFA;
}

.pdf-container-small {
    background-color: #FAFAFA;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
}

.pdf-container-small .small-image-container img {
    max-width: 100% !important;
    cursor: pointer;
}

.pdf-container>img {
    max-width: 90% !important;
    margin-bottom: 2vh;
}

.pdf-container {
    border: 3px solid #FAFAFA;
}

img {
    max-width: 50%;
}

.back-image-icon {
    width: auto;
    height: auto;
    max-width: 100%;
    cursor: pointer;
}

.pdf-images,
.pdf-images-on-preview {
    width: unset;
    /* height: unset; */
    height: 883.517px;
    max-width: 100%;
    margin-bottom: 3%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-top: 2px solid #FAFAFA;
}

.btn-cancel {
    width: 80%;
    height: 48px;
    border-radius: 10px;
    background: #FFFFFF;
    border-color: var(--custom-secondary-background-color);
    font-family: 'TT-Norms-Regular';
    font-size: 18px;
    font-weight: 600;
    color: var(--custom-secondary-text-color);
    padding-right: 0px;
    padding-left: 0px;
}

.action-buttons button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.action-buttons button svg {
    width: 14px;
    height: 14px;
    margin-right: 27px;
}

.btn-cancel:hover,
.btn-cancel:first-child:active {
    filter: brightness(0.9);
    color: var(--custom-secondary-text-color);
    background: #FFFFFF;
    border-color: var(--custom-secondary-background-color);
}

.btn-delegate {
    background: #FFFFFF;
    border-color: var(--custom-primary-background-color);
    color: var(--custom-primary-background-color);
}

.btn-delegate:hover {
    background: #FFFFFF;
    border-color: var(--custom-primary-background-color-hover);
    color: var(--custom-primary-background-color-hover);
}

.ant-modal .ant-modal-close {
    display: none;
}

.action-buttons {
    margin-top: 3vh;
}

.ant-input {
    font-family: 'TT-Norms-Regular';
    font-size: 16px;
}

.validation-error {
    color: red;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}

.loading-pages-container {
    position: absolute;
    bottom: 50px;
    left: calc(50% - 200px);
    width: 400px;
    z-index: 20;
}

.number-page {
    font-family: 'TT-Norms-Regular';
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 10px;
    text-align: center;
}

.current-number {
    border: 2px solid var(--custom-primary-background-color-opacity);
    border-radius: 7px;
    color: var(--custom-primary-background-color);
    padding: 0px 7px;
    display: inline-block;
}

.modal-autofirma {
    padding: 0px 0px 50px;
}

.text-align-start {
    margin-left: 27px;
    padding-left: 0px;
}

.text-align-end {
    padding-right: 0px;
    margin-right: 27px;
}

.single-button-width {
    width: 30% !important;
}

.sign-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.cancel-text {
    margin-left: 27px;
}

.document-preview-text {
    margin-left: 27px;
}

.document-icon-image {
    width: 14px;
    height: 14px;
    margin-right: 27px;
}

.only-scale-zoom {
    display: flex;
    align-items: flex-end;
}

@media (max-width: 1200px) and (min-width: 600px) {
    .document-preview-container {
        width: 503.66px;
        display: block;
    }

    .pdf-images,
    .pdf-images-on-preview {
        width: 503.66px;
        height: 713.518333px;
    }
}

@media (max-width: 600px) {
    .autofirma-error-button span {
        margin-left: 0;
        margin-right: 10px;
    }

    .document-preview-container {
        margin-top: 10px;
    }

    .document-preview-container hr {
        margin: 0px;
    }

    .document-name span {
        font-size: 10px;
    }

    .modal-container {
        font-family: 'TT-Norms-Regular';
        font-size: 16px;
    }

    .icon-image {
        width: auto;
        height: auto;
        max-width: 100%;
    }

    .action-buttons {
        margin-top: 3vh;
    }

    .document-preview-title {
        font-size: 14px;
        margin-bottom: 5px;
        height: 8vh;
    }

    .mobile-button>.btn-active,
    .btn-cancel {
        width: 100%;
    }

    .pdf-images,
    .pdf-images-on-preview {
        width: calc(100vw - calc(var(--bs-gutter-x)));
        height: 100%;
        max-width: unset;
        margin-bottom: unset;
    }

    .btn-mobile {
        width: 100% !important;
    }

    .text-align-button {
        padding-right: 0px
    }

    .sign-buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }

    .document-name,
    .download-button,
    .scale-zoom {
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .pdf-container {
        height: 53vh;
    }

    .document-icon-image {
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }

    .document-name {
        margin: auto;
        padding-left: 3px;
    }

    .toolbar {
        padding: 0px;
    }

    .signer-name {
        margin-left: 15px;
    }
}

@media (max-width: 600px) {

    .mobile-button>.btn-active,
    .btn-cancel {
        width: 100%;
    }

    .pdf-images,
    .pdf-images-on-preview {
        width: calc(99vw - calc(var(--bs-gutter-x)));
        height: 100%;
        /* width: 576px; */
        height: 816px;
        max-width: unset;
        margin-bottom: unset;
    }

    .document-name {
        font-size: 12px;
        margin-left: 0px;
    }

    .loading-pages-container {
        bottom: 50%;
    }

    .loading-pages-container .container-loading-box {
        height: unset;
    }

    .action-buttons button svg {
        width: 12px;
        height: 12px;
    }

    .action-buttons {
        margin-bottom: 5px;
    }

    .mobile-document-name {
        margin: auto;
    }

    .download-button {
        margin-right: 3%;
    }

    .mobile-button {
        margin-top: 2vh;
    }

    .centered-text {
        margin-left: auto;
    }

    .button-without-icons {
        display: unset !important;
        margin-left: auto
    }

    .hand-svg {
        min-width: 50px;
        max-width: 50px;
    }

    .cancel-text {
        margin-left: 12px;
    }
}

@media (max-width: 450px) {
    .pdf-images {
        width: 100%;
        height: auto;
    }

    .pdf-images-on-preview {
        width: 390px;
        height: 552.5003px;
    }
}

@media (max-width: 575px) {
    .pdf-container-small {
        display: none;
    }
}