.small-image-container {
    position: relative;
    max-width: 60% !important;
    margin: auto;
    margin-top: 5vh;
}

.small-image {
    cursor: pointer;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    width: unset;
    height: unset;
}

.small-container-images {
    margin-top: 10%;
}

.small-number {
    margin-top: 5%;
    font-family: 'TT-Norms-Regular';
    font-weight: 700;
}

.highlight-page {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}