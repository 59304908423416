.criipto-modal {
  width: 80%;
  height: 48px;
  border-radius: 10px;
  border-color: #151A35;
  font-family: 'TT-Norms-Regular';
  font-size: 18px;
  font-weight: 600;
  color: #151A35;
  white-space: nowrap;
  padding-right: 0px;
  padding-left: 0px;
  background: #FAFAFA;
}

.criipto-modal:hover,
.criipto-modal:active {
  border-color: #151A35 !important;
  background: #FAFAFA !important;
  color: #151A35 !important;
}