.batch-document-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: #f1f1f1 0px 4px 8px 0px;
    background-color: #f1f1f1;
    gap: 20px;
}

.batch-document-container button {
    width: 200px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.batch-document-container p {
    margin: 0;
    text-align: left;
}

.batch-document-container h5 {
    text-align: left;
    font-weight: bold;
}

.batch-document-container input {
    width: 24px;
    height: 24px;
}

.preview-documemt-batch {
    width: 82px;
    height: 116px;
}

.button-only svg {
    width: 14px;
    height: 14px;
    margin-right: 27px;
}

.btn-check:checked+.btn-active, .btn-active.active, .btn-active.show, .btn-active:first-child:active, :not(.btn-check)+.btn-active:active {
    background-color: var(--custom-primary-background-color-hover);
    border-color: var(--custom-primary-background-color-hover);
    color: var(--custom-primary-text-color);
}

.batch-document-item-arrow-right {
    display: none;
}

.selected-batch-item {
    outline: 2px solid var(--custom-secondary-background-color);
}

@media screen and (max-width: 600px) {
    .batch-document-container {
        display: flex;
        border-radius: 18px;
        gap: 10px;
    }

    .batch-document-container button {
        margin: auto;
    }

    .batch-document-container h5, .batch-document-container p {
        text-align: center;
    }

    .batch-document-container button {
        display: none;
    }

    .batch-document-container h5, .batch-document-container p {
        font-size: 14px;
        text-align: left;
    }

    .batch-document-item-arrow-right {
        display: block;
        margin: auto;
        width: 10px;
    }
}