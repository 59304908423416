.App {
  text-align: center;
  font-family: 'TT-Norms-Regular';
}

[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

[dir="ltr"] {
  direction: ltr;
  text-align: left;
}

[dir="rtl"] .form-check-input {
  margin-left: 7px !important;
}

[dir="rtl"] .btn-primary,
[dir="rtl"] .btn-secondary {
  padding-left: 27px;
  padding-right: 27px;
}

[dir="rtl"] .phone-number-input {
  text-align: right;
}

[dir="rtl"] .signer-name {
  margin-right: 2%;
}

[dir="rtl"] .page-image {
  direction: ltr;
  text-align: left;
}

[dir="rtl"] .welcome-text-container {
  text-align: right;
}

[dir="rtl"] .form-section-title,
[dir="rtl"] .document-name,
[dir="rtl"] .document-sign-header-text {
  text-align: right;
}

[dir="rtl"] .phone-number-input-disabled,
[dir="rtl"] .phone-number-input {
  direction: ltr;
  text-align: right;
}

[dir="rtl"] .document-name,
[dir="rtl"] .textbox-container .text-input,
[dir="rtl"] .qr-container .qr-instructions-title {
  text-align: right;
}

[dir="rtl"] .textbox-container input {
  text-align: right !important;
}

[dir="rtl"] .align-error-message {
  text-align: right !important;
}

[dir="rtl"] .form-check-label {
  margin-right: .5rem;
}

[dir="rtl"] .phone-number-modal {
  direction: ltr;
  display: inline-block;
}

[dir="rtl"] .qr-container p {
  text-align: right;
}

@media (max-width: 600px) or ((orientation: landscape) and (max-width: 1000px)) {

  .long-lang-text .btn,
  .long-lang-text .attached-button,
  .long-lang-text .dni-flow button,
  .long-lang-text .passport-flow button,
  .long-lang-text .select-all-button {
    font-size: 14px;
  }

  .long-lang-text .btn svg,
  .long-lang-text .btn img,
  .long-lang-text .attached-button svg,
  .long-lang-text .attached-button img,
  .long-lang-text .dni-flow button svg,
  .long-lang-text .passport-flow button svg {
    display: none;
  }

  .long-lang-text .btn span,
  .long-lang-text .attached-button span,
  .long-lang-text .dni-flow button span,
  .long-lang-text .passport-flow button span {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  .long-lang-text .delegation-buttons-container>div {
    padding: 0 4px;
  }
}