.document-container {
    background-color: #EDEDED;
    border-radius: 8px;
    padding: 5px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 10px;
}

.document-info-container {
    display: flex;
    flex-direction: column;
}

.document-container h5 {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    font-family: TT-Norms-Black;
    color: #151A35;
}

.document-container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #151A35;
    margin-bottom: 0;
}

.document-container svg {
    width: 16px;
    display: block;
    height: 100px;
    max-width: 50%;
}

@media screen and (max-width: 600px) {
    .document-container .form-check .form-check-input {
        margin-left: unset;
    } 

    .document-container {
        padding: 5px 10px;
    }

    .document-container h5 {
        font-size: 14px;
    }
}