.signature-success-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    background-color: var(--custom-primary-background-color);
    border-radius: 12px;
    margin-top: 30px;
}

.signature-success-header .custom-logo-image {
    height: 50%;
    width: auto;
}

@media screen and (max-width: 600px) {
    .signature-success-header {
        margin-top: 25px;
        height: 70px;
    }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
    .signature-success {
        height: 80vh !important;
    }

    .signature-success-header {
        height: 70px;
        margin-top: 10px;
    }
}