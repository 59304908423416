.documents-batch-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}

.select-all-button:disabled {
    background: #D9D9D9;
    border-color: #D9D9D9;
    color: #9C9C9C;
}

.select-all-button {
    width: 264px;
    height: 56px;
    border-radius: 10px;
    border: 2px solid var(--custom-secondary-background-color);
    color: var(--custom-secondary-text-color);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    background-color: white;
}

.next-documents-buttons-container {
    margin: 0;
}


.batch-button-container button {
    width: 50%!important;
    margin-left: 25%;
    padding: 0 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 600px) {
    .batch-button-container button { 
        width: 100%!important;
        margin-left: 0;
    }
}

