.document-sign-title {
    display: flex;
    gap: 30px;
    align-items: center;
}

.document-sign-header-text {
    display: flex;
    flex-direction: column;
}

.document-sign-header-text .signer-name {
    margin-left: 0;
}

.document-preview-container .form-check .form-check-input {
    margin-left: unset;
}

.document-preview-container .form-check {
    display: flex;
}

.signatureCanvas {
    background-color: #FAFAFA;
    margin-top: 15px;
}

.canvas-with-image {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('./../../assets/images/canvas-background.png');
}

.btn-landscape {
    height: 35px;
    width: unset;
    font-size: 14px;
}

.title-sign {
    font-size: 14px;
    margin-bottom: 2%;
}

.graph-image {
    max-width: unset;
    width: auto;
    height: auto;
}

.button-canvas {
    width: 100% !important;
}

.action-buttons {
    margin-bottom: 30px;
}

.landscape-buttons-container {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.landscape-buttons-container img, .landscape-buttons-container svg {
    width: 14px;
    height: 14px;
    margin-right: 27px;
}

.document-sign-portrait-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 20px;
}

@media (max-width: 600px) {
    .button-erase-signature {
        margin-bottom: 10px;
    }
}

@media (max-width: 1000px) and (orientation:landscape) {
    .sign-document-preview {
        width: unset;
    }

    .rgpd-text, .signatureCanvas {
        margin-top: 5px!important;
    }

    .sign-document-preview {
        margin-top: 5px;
    }
}