.checkbox-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #FAFAFA;
    padding: 25px;
    margin: 10px 0;
}

.form-view .inline-radio-buttons {
    display: flex;
    flex-direction: row!important;
    gap: 20px;
}

.form-view .inline-radio-buttons .form-check {
    align-items: center!important;
}

.advanced-form {
    background-color: transparent;
    padding: unset;
    margin: unset;
    font-family: unset;
}

.advanced-form .form-check {
    padding-left: unset;
    min-height: unset;
}

.advanced-form label.form-check-label {
    margin-left: 3px;
}

.error-container.advanced-form form {
    color: red;
    border: 2px solid red;
}

.error-container-checkbox.advanced-form form {
    color: red;
}

.advanced-form label.form-check-label {
    font-family: unset;
    text-align: left;
}

.checkbox-container > label {
    margin-left: 0px;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-view .checkbox-container > label, .form-view .radiobutton-container label {
    margin-bottom: 20px;
}

.form-view .checkbox-container form .form-check .form-check-label,  .form-view .radiobutton-container form .form-check .form-check-label{
    margin-bottom: unset;
}

.form-view .form-check .form-check-input {
    margin-left: unset;
}

.checkbox-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.checkbox-container form .form-check {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    align-items: flex-start;
}

.checkbox-container form .form-check input[type="radio"] {
    width: 32px;
    height: 32px;
    border: 2px solid #151A35;
}

.error-container  form .form-check input[type="radio"] {
    border: 2px solid red;
}

.checkbox-container form .form-check input[type="radio"]:checked {
    background-color: var(--custom-primary-background-color);
}

.checkbox-container form input[type="checkbox"] {
    width: 32px;
    height: 32px;
    border: 2px solid #151A35;
}

.advanced-form form input[type="checkbox"], .advanced-form form .form-check input[type="radio"]  {
    width: 16px;
    height: 16px;
    min-width: 16px;
    border: 1px solid #151A35;
}

.checkbox-container form {
    width: unset;
}

.advanced-form form input[type="checkbox"]  {
    border-radius: 0px;
}

.form-view .radiobutton-container .form-check .form-check-input {
    margin-left: 0;
    margin-right: 10px;
    flex-shrink: 0;
}

@media (max-width: 1200px) {
    .advanced-form form input[type="checkbox"], .advanced-form form .form-check input[type="radio"]  {
        min-width: 13px;
        width: 13px;
        height: 13px;
        border: 1px solid #151A35;
    }
}

@media (max-width: 600px) {
    .form-view .checkbox-container > label {
        display: block;
        margin: auto;
        margin-bottom: 20px;
    } 

    .form-view .inline-radio-buttons {
        margin: auto;
        gap: 50px;
    }
    
    .form-view .inline-radio-buttons .form-check {
        align-items: center!important;
    }

    .advanced-form, .advanced-form label {
        font-size: 8px!important;
    }

    .checkbox-container form .form-check input[type="radio"] {
        margin-right: 10px;
    }

    .advanced-form form input[type="checkbox"], .advanced-form form .form-check input[type="radio"]  {
        width: 10px;
        height: 10px;
        border: 1px solid #151A35;
        min-width: 10px;
    }

    /* .advanced-form form input[type="checkbox"], .advanced-form form .form-check input[type="radio"]  {
        min-width: unset;
    } */
}