@keyframes blinkBorder {
  0% {
    stroke: var(--custom-primary-background-color);;
  }
  50% {
    stroke: transparent;
  }
  100% {
    stroke: var(--custom-primary-background-color);;
  }
}

.signature-icon path:nth-child(2) {
  animation: blinkBorder 2s infinite;
}