.signature-panel-container {
    padding: 10px;
    margin: auto;
}

.signature-panel-container .signer-name {
    margin-left: 0;
    font-size: 20px;
}

.signature-panel-container button {
    height: 48px;
    font-size: 16px;
}

@media (max-width: 950px) and (orientation: landscape) {
    .success-qr-container-box {
        height: 85vh;
    }
}