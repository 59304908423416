.vid-copyright {
    font-family: TT-Norms;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.88px;
    text-align: left;
    color: #000000;
    text-align: center;
    margin: 30px 0;
}

@media screen and (max-width: 600px) {
    .vid-copyright {
        margin: 20px 0;
    }
}